  
<template>     
    <overview
        :items="getInvoiceConfigs"
        :headers="headers"
        :filters="getFilters"
        header-text="Facturatieconfiguraties"
        :on-edit="onEdit"
        local-storage-prefix="invoiceConfig"
        >
      <template v-slot:item.type="{item}">
        <v-chip :color="item.type == organizationTypes.MAIN ? 'green' : null" label>
          {{ item.type == organizationTypes.MAIN ? 'Hoofdorganisatie': 'Suborganisatie' }}
        </v-chip>
      </template>
    </overview>
</template>

<script>
import Overview from "@/components/shared/Overview";
import RouteNames from "@/router/RouteNames";
import { organizationTypes } from "../../../shared/constants/OrganizationTypes";
import { onlyUnique } from "../../../shared/utils/CollectionUtils";

export default {
    name: "InvoiceConfigOverview",
    components: {
        Overview
    },
    data() {
        return {
            RouteNames,
            promises: [
                this.$store.dispatch("invoiceConfigModule/fetchInvoiceConfig")
                    .then(data => this.invoiceConfig = data),
                this.$store.dispatch("suborganizationModule/fetchAllSuborganizations", this.$store.getters["authModule/getOrganizationId"])
                    .then(data => this.suborganizations = data),
                this.$store.dispatch("organizationModule/fetchOrganizationName", this.$store.getters["authModule/getOrganizationId"])
                    .then(data => this.organizationName = data?.name)
            ],
            invoiceConfig: {},
            suborganizations: [],
            organizationName: null,
            headers: [
                {
                    text: 'Type',
                    value: 'type',
                },
                {
                    text: 'Organisatie',
                    value: 'name',
                    cellClass: 'notranslate'
                },
                {
                    text: 'Voorvoegsel',
                    value: 'prefix',
                    cellClass: 'notranslate'
                },
                {
                    text: 'Beginnen op',
                    value: 'initialNumber',
                    cellClass: 'notranslate'
                }
            ],
            organizationTypes
        }
    },
    methods: {
        onEdit(config) {
            this.$router.push({name: RouteNames.SETTINGS_INVOICING_CONFIG_EDIT, params: {suborganizationId: config.suborganizationId}})
        }
    },
    computed: {
        getInvoiceConfigs() {
            let invoiceConfigs = [...(this.invoiceConfig?.subInvoiceConfigs || [])]
            let organizations = [...(this.suborganizations || [])]
            organizations.push(
                {
                    name: this.organizationName
                }
            )
            let mappedInvoiceConfigs = organizations.map(suborganization => {
                const invoiceConfig = invoiceConfigs.find(ic => ic.suborganizationId == suborganization.id)
                    || this.invoiceConfig
                
                return {
                    suborganizationId: suborganization.id,
                    name: suborganization.name,
                    ...invoiceConfig,
                    ...(invoiceConfig?.numbering || {}),
                    initialNumber: invoiceConfig?.numbering?.initialNumber?.padStart(6, 0),
                    numbering: null,
                    subInvoiceConfigs: null,
                    type: !suborganization.id ? organizationTypes.MAIN : organizationTypes.SUB
                }
            })
            return mappedInvoiceConfigs
        },
        getFilters() {
            return [
                {
                    title: "Type",
                    options: [
                        {
                            id: 'type#main',
                            label: 'Hoofdorganisatie',
                            active: false,
                            predicate: (ic) => ic.type && ic.type === organizationTypes.MAIN
                        },
                        {
                            id: 'type#sub',
                            label: 'Suborganisatie',
                            active: false,
                            predicate: (ic) => ic.type && ic.type === organizationTypes.SUB
                        },
                    ]
                },
                {
                    title: "Voorvoegsel",
                    options: this.getPrefixFilterOptions
                }
            ]
        },
        getPrefixFilterOptions() {
            return this.getInvoiceConfigs.map(ic => ic.prefix).filter(onlyUnique).map(prefix => ({
                id: "prefix#" + prefix,
                label: prefix,
                active: false,
                predicate: (ic) => ic.prefix && ic.prefix == prefix
            }))
        }
    }
}
</script>

<style scoped></style>